/* eslint-disable lingui/no-unlocalized-strings */
// Don't translate for Swedish units
import { Text } from "@components/Text/Text";

export const imagePathsAlerisAhc = {
  480: "/care/hospitalAtHome480.avif",
  768: "/care/hospitalAtHome768.avif",
  1366: "/care/hospitalAtHome1366.avif",
};

export const GuideTemplateAlerisAhc = () => {
  return (
    <>
      <Text element="p">
        Vi erbjuder dig kostnadsfri vård i hemmet under en kortare eller längre
        tid och det är dina behov som styr våra insatser. Vi utför medicinska
        och stödjande insatser för att behandla och lindra dina symtom och
        underlätta din vardag. Vi utformat din vårdplan tillsammans med dig och
        dina närstående.
      </Text>
      <Text element="p">
        I vårt ASIH-team kan du träffa läkare, sjuksköterska, undersköterska,
        arbetsterapeut, dietist, fysioterapeut och kurator. Ibland har vi också
        med oss studenter. Målet är att ge dig och dina närstående ökat
        välbefinnande och att du ska kunna vara hemma så mycket som möjligt utan
        att behöva vårdas på eller besöka sjukhus. Du kommer att få de flesta av
        dina läkemedel direkt från oss.
      </Text>
      <Text element="p">Du når oss via chatten mellan 07.30 - 22.00.</Text>
      <Text element="p">Du kan även ringa oss dygnet runt på:</Text>
      <Text element="p">kl 07.30 - 22.00: 08-606 42 12</Text>
      <Text element="p">kl 22.00 - 07.30: 079-071 91 35</Text>
      <Text element="p">Chatten stängd nattetid.</Text>
    </>
  );
};
