import { Trans } from "@lingui/react/macro";
import { msg, t } from "@lingui/core/macro";
import { useContext, useEffect, useRef, useState } from "react";
import Chat from "@components/Chat/Chat";
import { Loading } from "@components/Loading/Loading";
import ChatContext from "@chat/ChatContext";
import { formatTimestamp, makeMessages } from "@chat/useMatrixEvents";
import { useSendReadReceipt } from "@chat/useSendReadReceipt";
import { useMatrixRoom } from "@chat/useMatrixRoom";
import { sendTextMessage } from "@chat/matrixClient";
import { useChatScroll } from "@chat/useChatScroll";
import { SyncState } from "matrix-js-sdk";
import { useMatrixConnectionStatus } from "@chat/useMatrixConnectionStatus";
import { useMatrixTimelineEvents } from "@chat/useMatrixEvents";
import {
  lastEventReadByUserOtherThanUserId,
  useLastReadEventPerUser,
} from "@chat/useLastReadEvent";
import { getHistory } from "@chat/getHistory";
import { medicalTitle } from "@models/shifts";
import { useSendTypingNotification } from "@chat/useSendTypingNotification";
import { usePatientId } from "@/utils/usePatientId";
import { useLingui } from "@lingui/react";
import { getPatientRoomAlias } from "@chat/roomAlias";

const ChatComponent = () => {
  const [pendingMessage, setPendingMessage] = useState("");
  const { matrixClient } = useContext(ChatContext);
  const { _ } = useLingui();
  const chatConnection = useMatrixConnectionStatus(matrixClient);
  const patientId = usePatientId();
  const roomAlias = getPatientRoomAlias({ patientId, matrixClient });
  const { roomId, getRoom } = useMatrixRoom(matrixClient, roomAlias);
  const userId = matrixClient?.getUserId() ?? undefined;

  const timelineEvents = useMatrixTimelineEvents(matrixClient, roomId);
  const { sendNoLongerTypingNotification, updateTypingState } =
    useSendTypingNotification({
      matrixClient,
      roomId,
    });

  // Send no longer typing notification when leaving chat
  useEffect(() => {
    return () => {
      sendNoLongerTypingNotification();
    };
  }, [sendNoLongerTypingNotification]);

  const lastReadEventBySomeNonPatientUser = lastEventReadByUserOtherThanUserId(
    useLastReadEventPerUser({ getRoom }),
    userId,
  );

  const messages = userId ? makeMessages(timelineEvents, userId) : [];

  const latestMessage = messages[messages.length - 1]?.id ?? null;

  useSendReadReceipt(matrixClient, timelineEvents, userId);

  const messagePanelRef = useRef<HTMLDivElement>(null);
  const inputTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const { scrollMessagePanelToBottom } = useChatScroll({
    messagePanelRef,
    inputTextAreaRef,
    messages,
  });

  const sendMessage = () => {
    if (!matrixClient || !roomId) return;
    sendTextMessage(matrixClient, roomId, pendingMessage, () =>
      setPendingMessage(""),
    );
    sendNoLongerTypingNotification();
  };

  const room = getRoom();
  return matrixClient && room ? (
    <Chat>
      <Chat.MessagePanel
        getHistory={() => getHistory(matrixClient, room)}
        ref={messagePanelRef}
      >
        {messages.map((message) => {
          return message.sender.isMe ? (
            <Chat.MessageGroup
              key={message.id}
              sender="me"
              status={message.status}
              timestamp={formatTimestamp(message.epochTimestamp)}
              readIndicator={
                message.id === lastReadEventBySomeNonPatientUser?.eventId
              }
              isLatestMessage={latestMessage === message.id}
            >
              <Chat.Message type="sent-by-me" message={message.text} />
            </Chat.MessageGroup>
          ) : (
            <Chat.MessageGroup
              key={message.id}
              sender="other-person"
              senderName={message.sender.name ?? t`Din vårdgivare`}
              senderTitle={
                medicalTitle(message.sender.title, {
                  format: "long",
                }) ?? null
              }
              senderPhotoURL={message.sender.imageURL ?? null}
              status={message.status}
              timestamp={formatTimestamp(message.epochTimestamp)}
            >
              <Chat.Message type="received" message={message.text} />
            </Chat.MessageGroup>
          );
        })}
      </Chat.MessagePanel>
      {chatConnection === SyncState.Error ? (
        <Chat.InfoPanel type="error">
          <Trans>Chatten fungerar inte just nu</Trans>
        </Chat.InfoPanel>
      ) : (
        <></>
      )}
      <Chat.InputPanel>
        <Chat.InputTextArea
          inputTextAreaRef={inputTextAreaRef}
          rows={3}
          value={pendingMessage}
          onChange={(event) => {
            setPendingMessage(event.currentTarget.value);
            updateTypingState({ event, pendingMessage });
          }}
          onFocus={scrollMessagePanelToBottom}
        />
        <Chat.SendMessageButton
          aria-label={_(msg`Skicka`)}
          disabled={pendingMessage.trim() === ""}
          onClick={sendMessage}
          size="medium"
        />
      </Chat.InputPanel>
    </Chat>
  ) : (
    <Loading message={_(msg`Laddar chatt`)} />
  );
};

export { ChatComponent as Component };
export default ChatComponent;
