import { Trans } from "@lingui/react/macro";
import styles from "./OngoingVideoCallBannerContent.module.scss";
import { Text } from "@components/Text/Text";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import VideoCameraIcon from "@components/icons/VideoCameraIcon";

export const OngoingVideoCallBannerContent = () => {
  return (
    <div className={styles.ongoingVideoCallBannerContent}>
      <NotificationCircle isVisible>
        <VideoCameraIcon />
      </NotificationCircle>
      <Text element="div">
        <Trans>Videosamtal väntar</Trans>
      </Text>
      <Text element="div" weight="bold">
        <Trans>Svara</Trans>
      </Text>
    </div>
  );
};
