import styles from "@/routes/profile/secondaryNavCard.module.scss";
import { Heading } from "@components/Heading/Heading";
import CaretRightIcon from "@components/icons/CaretRightIcon";
import type { IUnstyledLink } from "@components/Link/Link";
import { UnstyledLink } from "@components/Link/Link";

export const SecondaryNavCard = ({
  icon,
  link,
}: {
  icon: React.ReactNode;
  link: Pick<IUnstyledLink, "to"> & {
    title: string;
  };
}) => {
  return (
    <UnstyledLink to={link.to}>
      <div className={styles.card}>
        <div className={styles.iconContainer}>{icon}</div>
        <Heading level="h2" size="h3">
          {link.title}
        </Heading>
        <div className={styles.arrowIcon}>
          <CaretRightIcon />
        </div>
      </div>
    </UnstyledLink>
  );
};
