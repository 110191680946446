import { Heading } from "@components/Heading/Heading";
import { Trans } from "@lingui/react/macro";
import { GoBackLink } from "@/routes/profile/goBackLink";
import styles from "./review.module.scss";
import { Loading } from "@components/Loading/Loading";
import { Suspense, lazy } from "react";
import { useWidgetId } from "./useWidgetId";
import { useLocation, useNavigate } from "react-router";

// Heavy dependency, lazy load it
const Widget = lazy(() => import("./TypeformWidget"));
const TIMEOUT_BEFORE_NAVIGATION = 5000;
const LANDING_PAGE_AFTER_SUCCESSFUL_FORM_SUBMISSION = "/profile";

export const Review = () => {
  const widgetId = useWidgetId();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateAfterTimeout = () => {
    const reviewFormLocation = location.pathname;

    setTimeout(() => {
      if (window.location.pathname === reviewFormLocation) {
        navigate(LANDING_PAGE_AFTER_SUCCESSFUL_FORM_SUBMISSION);
      }
    }, TIMEOUT_BEFORE_NAVIGATION);
  };

  return (
    <>
      <GoBackLink />
      <Heading level="h1" margin="top-and-bottom">
        <Trans>Betygsätt appen</Trans>
      </Heading>
      <div className={styles.container}>
        <Suspense fallback={<Loading />}>
          <Widget
            id={widgetId}
            style={{ width: "100%", height: "500px" }}
            onSubmit={navigateAfterTimeout}
          />
        </Suspense>
      </div>
    </>
  );
};
