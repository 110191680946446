/* eslint-disable lingui/no-unlocalized-strings */
// Don't translate for Swedish units
import { Text } from "@components/Text/Text";

export const imagePathsSivNsvt = {
  480: "/care/hospitalAtHome480.avif",
  768: "/care/hospitalAtHome768.avif",
  1366: "/care/hospitalAtHome1366.avif",
};

export const GuideTemplateSivNsvt = () => {
  return (
    <>
      <Text element="p">
        Vi från Närsjukvårdsteamet kommer att följa upp dig hemma den närmsta
        tiden efter att du kommer hem från Lasarettet. Vi kommer bland annat ta
        kontroller.
      </Text>
      <Text element="p">
        I schemat kan du se när vi kommer till dig nästa gång.
      </Text>
      <Text element="p">
        Kostnad enligt Västra Götalandsregionens taxa, 400 kr för läkarbesök och
        200 kr för sjuksköterskebesök. Högkostnadsskydd gäller. Kostnadsfritt
        för personer över 85 år.
      </Text>
      <Text element="p">
        Vid frågor, försämring eller förhinder kontakta oss i chatten på plattan
        eller ring oss på telefonnummer 0322-22 66 11 vardagar kl 8-16.
      </Text>
    </>
  );
};
