import { t } from "@lingui/core/macro";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "react-oidc-context";
import { HelmetProvider } from "react-helmet-async";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router";
import { Index as PatientContainer } from ".";
import { Navigate } from "react-router";
import { Schedule } from "./routes/schedule/schedule";
import { Login } from "./routes/login";
import RequireAuth from "./components/requireAuth";
import Chat from "@/routes/chat/chat";
import { Profile } from "@/routes/profile/profile";
import { Review } from "./routes/profile/review";
import { VideoMeeting } from "@/routes/VideoMeeting/VideoMeeting";
import { ENVIRONMENT, userManager } from "./utils/envUtils";
import { Measurements } from "./routes/measurements/measurements";
import { finishActivityOccurrence } from "./api/activities";
import * as Sentry from "@sentry/react";
import { PatientNotes } from "@/routes/profile/patientNotes";
import { Guide } from "@/routes/profile/guide";
import { I18nApp } from "./I18nApp";
import { About } from "./routes/profile/about";
import { Trans } from "@lingui/react/macro";

Sentry.init({
  debug: import.meta.env.VITE_SENTRY,
  enabled: import.meta.env.VITE_SENTRY || import.meta.env.PROD,
  dsn: "https://7c536ed5f082866540ffb16653b15463@o4506739699220480.ingest.us.sentry.io/4507072367689728",
  environment: ENVIRONMENT,
  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (import.meta.env.DEV && import.meta.env.VITE_MSW) {
  const { worker } = await import("./mocks/browser");
  worker.start({
    onUnhandledRequest(req) {
      if (req?.url?.includes("medoma"))
        console.error(
          "Found an unhandled %s request to %s",
          req.method,
          req.url,
        );
    },
  });
  worker.listHandlers();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count) => {
        return count < 3;
      },
    },
  },
});

// Force stop background token refresh when Care is backgrounded
window.addEventListener("visibilitychange", () => {
  if (document.visibilityState === "hidden") {
    userManager.stopSilentRenew();
  } else {
    // Restart background token refresh when Care is foregrounded
    userManager.startSilentRenew();
    // Check for expired or missing token
    userManager
      .getUser()
      .then((user) => {
        if (!user || user.expired) {
          // Pre-emptively trigger an MFA check rather than waiting for a failed request
          userManager.signinRedirect({
            state: window.location.pathname,
            acr_values: "mfa",
          });
        }
      })
      .catch(() => {
        userManager.signinRedirect({
          state: window.location.pathname,
          acr_values: "mfa",
        });
      });
  }
});

// Navigation helper to allow the wrapper app to navigate to a specific route without reloading the whole app
window.onmessage = (event) => {
  if (event.origin !== window.origin) return;
  if (event.data.source !== "wrapper-app") return;

  console.log("Received message from wrapper app", event.data);

  // Old
  if (event.data.action === "navigate:video") {
    console.log("Navigating to video because of wrapper app command");
    router.navigate("/video");
  }

  // ACS
  if (event.data.action === "navigate:video:acs") {
    console.log("Navigating to acs video because of wrapper app command");
    router.navigate("/video?video-service=acs");
  }

  // Jitsi
  if (event.data.action === "navigate:video:jitsi") {
    console.log("Navigating to jitsi video because of wrapper app command");
    router.navigate("/video?video-service=jitsi");
  }

  if (event.data.action === "navigate:schedule") {
    console.log("Navigating to schedule because of wrapper app command");
    router.navigate("/schedule");
  }

  if (
    event.data.action === "finishActivityOccurrence" &&
    event.data.activityId &&
    event.data.occurrenceId &&
    typeof event.data.activityId === "string" &&
    typeof event.data.occurrenceId === "string"
  ) {
    console.log(
      `Finishing activity occurrence ${event.data.activityId} ${event.data.occurrenceId} because of wrapper app command`,
    );
    try {
      finishActivityOccurrence(
        event.data.activityId,
        event.data.occurrenceId,
      ).then(() => queryClient.invalidateQueries({ queryKey: ["schedule"] }));
    } catch (_error) {
      const errorMessage = t`Dina mätvärden har rapporterats in, men ett tekniskt fel gjorde att det inte gick att färdigmarkera aktiviteten i schemat. Skriv till oss i chatten så hjälper vi dig.`;
      Sentry.captureException(
        new Error(`An error alert was shown: ${errorMessage}`),
      );

      alert(errorMessage);
    }
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <PatientContainer />
      </RequireAuth>
    ),
    errorElement: (
      <div>
        <Trans>
          <h1>Något gick fel</h1>
          <p>Ladda om sidan</p>
        </Trans>
      </div>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="schedule" replace={true} />,
      },
      { path: "schedule", element: <Schedule /> },
      { path: "measurements", element: <Measurements /> },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "profile",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Profile />,
          },
          {
            path: "patientNotes",
            element: <PatientNotes />,
          },
          {
            path: "patientGuide",
            element: <Guide />,
          },
          {
            path: "about",
            element: <About />,
          },
          {
            path: "review",
            element: <Review />,
          },
        ],
      },
      { path: "video", element: <VideoMeeting /> },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "status",
    element: <p>OK</p>,
  },
]);

// https://github.com/authts/react-oidc-context/blob/f175dcba6ab09871b027d6a2f2224a17712b67c5/src/AuthProvider.tsx#L20-L30
const removeCodeAndStateParametersFromURL = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

const root = createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <AuthProvider
      userManager={userManager}
      onSigninCallback={removeCodeAndStateParametersFromURL}
    >
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <I18nApp>
            <RouterProvider router={router} />
          </I18nApp>
        </HelmetProvider>
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>,
);
