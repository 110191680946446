import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import styles from "./activityCard.module.scss";
import { isPast } from "date-fns";
import { CardHeading } from "./cardHeading";
import type {
  IActivityOccurrenceWithoutPatient,
  IMeasurementsType,
} from "@models/activities";
import { measurementsDictionary, timeOfDaySchema } from "@models/activities";
import { Text } from "@components/Text/Text";
import clsx from "clsx";
import { useNavigate } from "react-router";
import { FilledButton } from "@components/Button/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { finishActivityOccurrence } from "@/api/activities";
import { AssigneeList } from "./assigneeList";
import { generateQueryString } from "@/api/Helpers";
import { z } from "zod";
import * as Sentry from "@sentry/react";
import { i18n } from "@lingui/core";
import { useLingui } from "@lingui/react";

type IActivityCard = {
  activityOccurrence: IActivityOccurrenceWithoutPatient;
};

export const ActivityCard = ({ activityOccurrence }: IActivityCard) => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: finishActivityOccurrenceMutation, isPending } = useMutation({
    mutationFn: ({
      activityId,
      occurrenceId,
    }: {
      activityId: string;
      occurrenceId: string;
    }) => {
      return finishActivityOccurrence(activityId, occurrenceId);
    },
    onError: (error) => {
      // TODO: Use ErrorUtils
      const isErrorWithKnownErrorCode = (
        error: unknown,
      ): error is { response: { data: { code: "OccurrenceFinished" } } } => {
        return z
          .object({
            response: z.object({
              data: z.object({
                code: z.literal("OccurrenceFinished"),
              }),
            }),
          })
          .safeParse(error).success;
      };
      if (
        isErrorWithKnownErrorCode(error) &&
        error.response.data.code === "OccurrenceFinished"
      ) {
        return queryClient.invalidateQueries({ queryKey: ["schedule"] });
      } else {
        const errorMessage = t`Gick inte att färdigmarkera aktiviteten. Försök igen, eller skriv till oss i chatten så hjälper vi dig.`;
        Sentry.captureException(
          new Error(`An error alert was shown: ${errorMessage}`),
        );
        alert(errorMessage);
      }
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["schedule"] });
    },
  });

  const {
    title,
    start,
    end,
    category,
    status,
    id: occurrenceId,
    activityId,
    timeOfDay,
  } = activityOccurrence;

  const CallToAction = () => {
    if (category === "PatientTask" && isPast(start)) {
      return (
        <div className={styles.cta}>
          <FilledButton
            size="large"
            disabled={isPending}
            onClick={() =>
              finishActivityOccurrenceMutation({ activityId, occurrenceId })
            }
          >
            <Trans>Markera som klar</Trans>
          </FilledButton>
        </div>
      );
    } else if (category === "PatientMeasurementTask" && isPast(start)) {
      return (
        <div className={styles.cta}>
          <FilledButton
            size="large"
            onClick={() =>
              navigate(
                `/measurements${generateQueryString(
                  mapMeasurementsToQueryParameters(
                    activityOccurrence.measurements,
                    occurrenceId,
                    activityId,
                  ),
                )}`,
              )
            }
          >
            <Trans>Gå till mätning</Trans>
          </FilledButton>
        </div>
      );
    } else {
      return null;
    }
  };

  const formattedTimespan = (binding: string) => {
    return start !== undefined && end !== undefined
      ? `${i18n.date(start, { timeStyle: "short" })}${binding}${i18n.date(end, { timeStyle: "short" })}`
      : null;
  };

  const timespan = formattedTimespan(` ${t`och`} `);

  const activityOccurrenceWithAssignedEmployees = {
    ...activityOccurrence,
    assignees:
      category !== "PatientTask" &&
      category !== "PatientMeasurementTask" &&
      status !== "notReady"
        ? activityOccurrence.assignees.filter(({ employee }) => employee)
        : [],
  };
  const activityOccurrenceHasAssignedEmployees =
    activityOccurrenceWithAssignedEmployees.assignees.length > 0;

  const timeSpanText =
    timeOfDay === timeOfDaySchema.Values.Any
      ? t`Under dagen`
      : start && end && timespan
        ? t`Mellan ${timespan}`
        : null;

  const contactText =
    timeOfDay === timeOfDaySchema.Values.Any
      ? t`Vi kontaktar dig under dagen`
      : timespan
        ? t`Vi kontaktar dig mellan ${timespan}`
        : null;

  const pushNotificationText = t`Du får en notis när samtalet startar`;
  const videoCallText = `${contactText}. ${pushNotificationText}.`;

  return (
    <article className={clsx(styles.activityCard)}>
      <CardHeading
        category={category}
        title={title}
        formattedTimespan={
          category === "VideoCall" ? videoCallText : timeSpanText
        }
      />
      {category === "VideoCall" && activityOccurrenceHasAssignedEmployees ? (
        <AssigneeList
          assignees={activityOccurrenceWithAssignedEmployees.assignees}
        />
      ) : category === "PatientMeasurementTask" ? (
        <ul>
          {activityOccurrence.measurements.map((measurement) => (
            <li key={measurement}>
              <Text element="span" weight="regular" size="small">
                {_(measurementsDictionary[measurement])}
              </Text>
            </li>
          ))}
        </ul>
      ) : null}
      <CallToAction />
    </article>
  );
};

const mapMeasurementsToQueryParameters = (
  measurements: IMeasurementsType[],
  occurrenceId: string,
  activityId: string,
) => {
  const queryParameters = {
    "blood-pressure-sys": false,
    "blood-pressure-dia": false,
    "blood-glucose": false,
    weight: false,
    temperature: false,
    saturation: false,
    pulse: false,
    activityId,
    occurrenceId,
    // Tell the backend to put `destination=schedule` in the reply URL
    // so that the native app can redirect back to the schedule
    destination: "schedule",
  };

  measurements.forEach((measurement) => {
    switch (measurement) {
      case "bloodPressure":
        queryParameters["blood-pressure-sys"] = true;
        queryParameters["blood-pressure-dia"] = true;
        break;
      case "bloodGlucose":
        queryParameters["blood-glucose"] = true;
        break;
      case "weight":
        queryParameters.weight = true;
        break;
      case "temperature":
        queryParameters.temperature = true;
        break;
      case "saturation":
        queryParameters.saturation = true;
        break;
      case "pulse":
        queryParameters.pulse = true;
        break;
      default:
        break;
    }
  });

  // only return if true
  return Object.fromEntries(
    Object.entries(queryParameters).filter(([, value]) => value),
  );
};
