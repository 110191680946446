import { useUserProfile } from "@/utils/useUserProfile";

export const useWidgetId = () => {
  const { unit } = useUserProfile();

  const useWidgetIdByUnit = (() => {
    switch (unit) {
      case "alerisahc":
        return "jpgLawXH";
      case "sivnsvt":
        return "iEhnycBV";
      case "ognvk":
        return "kvADEjgq";
      default:
        return "sNzwjI5z";
    }
  })();

  return useWidgetIdByUnit;
};
